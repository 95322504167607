import { z } from "zod";

import type { TrackLike } from "@/types";

export type SampleTrack = TrackLike;
export type ProductTrack = TrackLike;

export type Product = {
  id: string;
  name: string;
  authors: string[];
  description: string;
  image: {
    thumbnail: string;
    full: string;
  };
  price: {
    amount: number;
    currency: string;
  };
  sample: SampleTrack | undefined;
  tracks: ProductTrack[];
  canPurchase: boolean;
  canPlay: boolean;
  createdAt: Date;
};

export const productSchema = z
  .object({
    product: z.object({
      id: z.string(),
      name: z.string(),
      descriptionHtml: z.string(),
      price: z.coerce.number(),
      currencyCode: z.string(),
      inAppCanConsume: z.boolean(),
      inAppForSale: z.boolean(),
      created: z.date(),
    }),
    author: z.array(
      z.object({
        name: z.string(),
      }),
    ),
    image: z.object({
      urlMindlyappThumb: z.string().url(),
      urlMindlyappImage: z.string().url(),
    }),
    sample: z.union([
      z.object({
        id: z.string(),
        filename: z.string(),
        url: z.string().url(),
      }),
      z.object({
        id: z.undefined(),
        filename: z.undefined(),
        url: z.undefined(),
      }),
    ]),
    productFile: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        filetype: z.string(),
        filesize: z.coerce.number(),
        url: z.string().url(),
      }),
    ),
  })
  .transform<Product>((data) => ({
    id: data.product.id,
    name: data.product.name,
    authors: data.author.map((author) => author.name),
    description: data.product.descriptionHtml,
    image: {
      thumbnail: data.image.urlMindlyappThumb,
      full: data.image.urlMindlyappImage,
    },
    price: {
      amount: data.product.price / 100,
      currency: data.product.currencyCode,
    },
    sample: data.sample.id
      ? {
          id: data.sample.id,
          trackName: data.sample.filename,
          artistName: data.author.map((author) => author.name).join(", "),
          collectionName: data.product.name,
          audioUrl: data.sample.url,
          artworkUrl: data.image.urlMindlyappImage,
        }
      : undefined,
    tracks: data.productFile
      .filter((file) => file.filetype === "audio")
      .map((file) => ({
        id: file.id,
        trackName: file.name,
        artistName: data.author.map((author) => author.name).join(", "),
        collectionName: data.product.name,
        audioUrl: file.url,
        artworkUrl: data.image.urlMindlyappImage,
      })),
    canPurchase: data.product.inAppForSale,
    canPlay: data.product.inAppCanConsume,
    createdAt: data.product.created,
  }));
