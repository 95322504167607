import decamelizeKeys from "decamelize-keys";

import { createMiddleware } from ".";

type Options = Parameters<typeof decamelizeKeys>[1];

export function snakeCaseRequestMiddleware(options?: Options) {
  return createMiddleware((next) => (url, opts) => {
    const { body } = opts;

    const transformedBody =
      typeof body === "string"
        ? JSON.stringify(
            decamelizeKeys(JSON.parse(body), { deep: true, ...options }),
          )
        : undefined;

    return next(url, { ...opts, body: transformedBody });
  });
}
